@import ../../../styles/helpers

.fieldset
    & > .field
        &:not(:last-child)
            margin-bottom: 32px
            +t
                margin-bottom: 24px

.row
    display: flex
    margin: 0 -8px
    +m
        display: block
        margin: 0
    .field
        flex: 0 0 calc(50% - 16px)
        width: calc(50% - 16px)
        margin: 0 8px
        +m
            width: 100%
            margin: 0
            &:not(:last-child)
                margin-bottom: 24px
    &:not(:last-child)
        margin-bottom: 32px
        +t
            margin-bottom: 24px